import React, { Component } from "react";
import Styles from "./styles.module.scss";
import GoogleLogin from "react-google-login";

class Login extends Component {
  static defaultProps = {
    onSuccess: (provider, token) => console.log(provider, token),
    onFailure: () => console.log("oh noes")
  };

  render = () => {
    return (
      <div className={Styles.View}>
        <GoogleLogin
          clientId="934384558184-rqpj1039hf1rl7vlrgcmu9bi4v7l7t06.apps.googleusercontent.com"
          buttonText="Login"
          onSuccess={d => this.props.onSuccess("google", d.tokenId)}
          onFailure={this.onFailure}
        />
      </div>
    );
  };
}
export { Login as default };
