import React, { useState, useEffect } from "react";
import { Get, Put } from "utils";
import ShopifyLink from "components/ShopifyLink";
import moment from "moment";

import Styles from "./styles.module.scss";


const Exchange = props => {
  const [state, setState] = useState(null);
  const [itemsReceived, setItemsReceived] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [saving, setSaving] = useState(false);

  const refresh = async () => {
    try {
      const resp = await Get(`/admin/exchanges/${props.id}`);
      setState(resp);
      setItemsReceived(resp.exchange.original_order_items_received);
      setShouldRefresh(false);
    } catch (e) {
      console.log(e);
    }
  };
  const save = async () => {
    try {
      setSaving(true);
      await Put(`/admin/exchanges/${state.exchange.id}`, {
        original_order_items_received: itemsReceived
      });
      setSaving(false);
      refresh();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (shouldRefresh === true) refresh();
    // eslint-disable-next-line
  }, [shouldRefresh]);

  if (!state) {
    return <div>Loading</div>;
  }

  return (
    <div className={Styles.Exchange}>
      <div>Exchange Request ID: {state.exchange.id}</div>
      <div>Customer Email: {state.exchange.email}</div>
      <div>Status: {state.exchange.status}</div>
      <div>
        Created:{" "}
        {`${moment(state.exchange.created).format("MM/DD/YYYY")} (${moment(
          state.exchange.created
        ).fromNow()})`}
      </div>

      <div>
        <div className={Styles.Button}>
          <i
            className="material-icons"
            onClick={() => setItemsReceived(!itemsReceived)}
          >
            {itemsReceived ? "check_box" : "check_box_outline_blank"}
          </i>

          <div>Original Order Items received</div>
        </div>

        {/* <input
          type="checkbox"
          checked={this.itemsReceived()}
          }
        /> */}
      </div>

      <div className={Styles.Order}>
        <div>Original Order</div>
        <div className={Styles.InlineLabel}>
          <div>ID: </div>
          <ShopifyLink
            orderId={state.original_order.id}
            label={state.original_order.id}
          />
        </div>
        <div>Items</div>
        {state.original_order.line_items.map((item, idx) => (
          <div key={`li-${idx}`}>
            <div>
              {item.properties.map((p, idx) => (
                <div key={`i-${idx}`}>
                  <div>{p.name}</div>
                  <div>{p.value}</div>
                </div>
              ))}
            </div>
            <div>{state.original_order.quantity}</div>
            <div>{state.original_order.variant_id}</div>
          </div>
        ))}
      </div>
      <button onClick={save}>{saving ? "Saving" : "Save"}</button>
    </div>
  );
};

export { Exchange as default };
