import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Link,
  Switch
} from "react-router-dom";
import Exchanges from "components/Exchanges";
// import Exchange from "components/Exchange";
import Index from "components/Index";
import Login from "components/Login";
import { Post, Get } from "utils";
import Styles from "./styles.module.scss";

class App extends Component {
  state = {
    loading: true,
    authenticated: false
  };

  setPath = path => this.setState({ path });

  authenticate = (provider, token) => {
    return Post("/admin/auth", { provider, token })
      .then(({ token }) => {
        window.localStorage.setItem("token", token);
        this.setState({ authenticated: true, loading: false });
      })
      .catch(err => {
        this.setState({ authenticated: false, loading: false });
      });
  };

  checkAuth = () => {
    return Get("/admin/auth")
      .then(() => {
        this.setState({ authenticated: true, loading: false });
      })
      .catch(err => {
        this.setState({ authenticated: false, loading: false });
      });
  };

  logout = () => {
    window.localStorage.removeItem("token");
    this.setState({ authenticated: false, loading: false });
  };

  componentWillMount = () => {
    this.checkAuth();
  };

  routes = () => {
    if (!this.state.authenticated) {
      return (
        <Switch>
          <Route
            path="/"
            exact
            render={() => <Login onSuccess={this.authenticate} />}
          />
          <Redirect to="/" />
        </Switch>
      );
    }

    return (
      <>
        <div className={Styles.LeftDrawer}>
          <Link
            to="/"
            className={
              this.state.path === "/"
                ? Styles.LeftDrawerIconLinkActive
                : Styles.LeftDrawerIconLink
            }
          >
            <i className="material-icons">home</i>
          </Link>
          <Link
            to="/exchanges"
            className={
              this.state.path === "/exchanges"
                ? Styles.LeftDrawerIconLinkActive
                : Styles.LeftDrawerIconLink
            }
          >
            <i className="material-icons">refresh</i>
          </Link>
          <Link
            to="/"
            onClick={this.logout}
            className={Styles.LeftDrawerIconLink}
          >
            <i className="material-icons">exit_to_app</i>
          </Link>
        </div>
        <Switch>
          <div className={Styles.View}>
            <Route path="/" exact component={Index} />
            <Route path="/exchanges" exact component={Exchanges} />
          </div>
        </Switch>
      </>
    );
  };

  render() {
    if (this.state.loading) {
      return <div>Loading</div>;
    }
    return (
      <Router>
        <div className={Styles.App}>{this.routes()}</div>
      </Router>
    );
  }
}
export { App as default };
