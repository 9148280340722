import React, { useState, useEffect } from "react";
import { Get, Put } from "utils";
import ReactModal from "react-modal";

import ShopifyLink from "components/ShopifyLink";
import Exchange from "components/Exchange";
import moment from "moment";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "./modal.css";
import "./loading.css";

const ToggleItemsReceivedButton = props => {
  const [updating, setUpdating] = useState(false);
  const received = props.original_order_items_received;

  const toggleItemsReceived = async () => {
    try {
      setUpdating(true);
      await Put(`/admin/exchanges/${props.id}`, {
        original_order_items_received: !received
      });
      props.onSuccess();
    } catch (e) {
      console.log(e);
    }
  };

  if (updating) {
    return (
      <div className="spinner">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    );
  }

  return (
    <i
      style={{ cursor: "pointer" }}
      className="material-icons"
      onClick={() => toggleItemsReceived()}
    >
      {received ? "check_box" : "check_box_outline_blank"}
    </i>
  );
};

const Exchanges = () => {
  const [data, setData] = useState([]);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [viewExchangeId, setViewExchangeId] = useState(null);
  const fetchExchanges = async () => {
    try {
      const resp = await Get("/admin/exchanges");
      setData(resp.exchanges);
      setShouldRefresh(false);
    } catch (e) {
      console.log(e);
    }
  };
  const refresh = () => setShouldRefresh(true);

  useEffect(() => {
    if (shouldRefresh === true) fetchExchanges();
  }, [shouldRefresh]);

  const columns = [
    // {
    //   Header: "ID",
    //   accessor: "id",
    //   Cell: props => (
    //     <div onClick={() => setViewExchangeId(props.value)}>{props.value}</div>
    //   )
    // },
    { Header: "Email", accessor: "email" },
    { Header: "Status", accessor: "status" },
    {
      Header: "Original Order Number",
      accessor: "original_order_number",
      Cell: props =>
        props.value === 0 ? (
          "N/A"
        ) : (
          <ShopifyLink
            orderId={props.original.original_order_id}
            label={props.value}
          />
        ),
      filterMethod: (filter, row) => {
        if (row.original_order_number.toString().includes(filter.value)) {
          return true;
        }
        return false;
      }
    },
    {
      Header: "New Order ID",
      accessor: "new_order_id",
      Cell: props => {
        return props.value === 0 ? (
          "N/A"
        ) : (
          <ShopifyLink orderId={props.value} label={props.value} />
        );
      }
    },
    {
      Header: "Created",
      accessor: "created",
      Cell: props => moment(props.value).format("MM-DD-YYYY"),
      filterMethod: (filter, row) => {
        if (
          moment(row.created)
            .format("MM-DD-YYYY")
            .includes(filter.value)
        ) {
          return true;
        }
        return false;
      }
    },
    {
      Header: "Items Received",
      Cell: props => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ToggleItemsReceivedButton
              {...props.original}
              onSuccess={refresh}
            />
          </div>
        );
      },
      accessor: "original_order_items_received",
      filterMethod: (filter, row) => {
        if (filter.value === "everything") {
          return true;
        }
        if (filter.value === "true") {
          return row.original_order_items_received === true;
        }

        return row.original_order_items_received === false;
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "everything"}
          >
            <option value="everything">Everything</option>
            <option value="true">Received</option>
            <option value="false">Not Received</option>
          </select>
        );
      }
    }
  ];

  return (
    <>
      <ReactTable
        style={{ fontSize: "12px", fontWeight: 300 }}
        data={data}
        columns={columns}
        filterable={true}
        className="-striped -highlight"
        defaultSorted={[
          {
            id: "created",
            desc: true
          }
        ]}
      />
      <ReactModal
        isOpen={!!viewExchangeId}
        onRequestClose={() => {
          setViewExchangeId(null);
          setShouldRefresh(true);
        }}
      >
        <button onClick={() => setViewExchangeId(null)}>Close Modal</button>
        {viewExchangeId && <Exchange id={viewExchangeId} />}
      </ReactModal>
    </>
  );
};

export { Exchanges as default };
