import React from "react";

const baseURL = "https://dearborn-denim-apparel.myshopify.com/admin/orders/";

const ShopifyLink = props => {
  return (
    <div className={props.className}>
      <a target="_blank" rel="noopener noreferrer" href={`${baseURL}${props.orderId}`}>
        {props.label}
      </a>
    </div>
  );
};

export { ShopifyLink as default };
