const apiBase = "https://api.dearborndenim.us/v1";
// process.env.NODE_ENV === "development"
//   ? "/v1"
//   : "https://api.dearborndenim.us/v1";

const _fetch = (url, options = {}, sendJSON = false) => {
  let defaultOptions = {
    headers: {}
  };
  const token = window.localStorage.getItem("token");

  if (sendJSON) {
    defaultOptions = {
      headers: {
        "Content-Type": "application/json"
      }
    };
  }

  if (token !== "") {
    defaultOptions.headers["Authorization"] = `bearer ${token}`;
  }

  return fetch(`${apiBase}${url}`, {
    ...defaultOptions,
    ...options
  }).then(response => {
    if (response.status >= 400) {
      return response.json().then(err => {
        throw new Error(err.message);
      });
    }

    if (response.status >= 202 && response.status < 205) {
      return true;
    }

    return response.json();
  });
};

const Get = (url, options = {}) => _fetch(url, options);

const Post = (url, body = {}, options = {}) =>
  _fetch(url, { method: "POST", body: JSON.stringify(body), ...options }, true);

const Put = (url, body = {}, options = {}) =>
  _fetch(url, { method: "PUT", body: JSON.stringify(body), ...options }, true);

const Delete = (url, options = {}) =>
  _fetch(url, { method: "DELETE", ...options });

export { Get, Post, Put, Delete };
